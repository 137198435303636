// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MedicalRecordItem_medical-record-item__ubgmu {
  background-color: #f9f9f9;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.MedicalRecordItem_dropdown-menu-btn__CWjuS {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/MedicalRecordItem.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,qCAAqC;AACvC;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,2BAA2B;AAC7B","sourcesContent":[".medical-record-item {\n  background-color: #f9f9f9;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n}\n\n.dropdown-menu-btn {\n  border: none !important;\n  background: none !important;\n  box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"medical-record-item": `MedicalRecordItem_medical-record-item__ubgmu`,
	"dropdown-menu-btn": `MedicalRecordItem_dropdown-menu-btn__CWjuS`
};
export default ___CSS_LOADER_EXPORT___;
