// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.term-container {
  width: 980px;
  max-width: 100%;
  margin-inline: auto;
  padding: 0 23px 50px 23px;
  margin-top: 80px;
  /* font-family: 'Open Sans'; */
}

li {
  text-align: left;
}

.sub-text-medium {
  font-size: 16px;
  line-height: 1;
  text-align: left;
}

.links {
  color: #006dca;
  font-size: 16px;
  cursor: pointer;
}
.head-text-bold {
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0 20px 0;
}
.head-text-big {
  font-weight: 700 !important;
  font-size: 42px;
  /* font-size: clamp(3.275rem, 5.5vw, 1.75rem); */
  text-align: left;
  color: black;
  margin-bottom: 13px;
}
.head-text-medium-lt {
  text-align: left;
  font-size: 24px;
  font-weight: 400 !important;
  margin: 20px 0 20px 0;
}

.sub-text {
  text-align: left;
  line-height: 2.1;
  color: #676971;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/TermsCondition.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,2BAA2B;EAC3B,eAAe;EACf,gDAAgD;EAChD,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,2BAA2B;EAC3B,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".term-container {\r\n  width: 980px;\r\n  max-width: 100%;\r\n  margin-inline: auto;\r\n  padding: 0 23px 50px 23px;\r\n  margin-top: 80px;\r\n  /* font-family: 'Open Sans'; */\r\n}\r\n\r\nli {\r\n  text-align: left;\r\n}\r\n\r\n.sub-text-medium {\r\n  font-size: 16px;\r\n  line-height: 1;\r\n  text-align: left;\r\n}\r\n\r\n.links {\r\n  color: #006dca;\r\n  font-size: 16px;\r\n  cursor: pointer;\r\n}\r\n.head-text-bold {\r\n  text-align: left;\r\n  font-size: 24px;\r\n  font-weight: 700;\r\n  margin: 20px 0 20px 0;\r\n}\r\n.head-text-big {\r\n  font-weight: 700 !important;\r\n  font-size: 42px;\r\n  /* font-size: clamp(3.275rem, 5.5vw, 1.75rem); */\r\n  text-align: left;\r\n  color: black;\r\n  margin-bottom: 13px;\r\n}\r\n.head-text-medium-lt {\r\n  text-align: left;\r\n  font-size: 24px;\r\n  font-weight: 400 !important;\r\n  margin: 20px 0 20px 0;\r\n}\r\n\r\n.sub-text {\r\n  text-align: left;\r\n  line-height: 2.1;\r\n  color: #676971;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
