// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-icon-filter {
  position: relative;
  right: 25px;
  top: 2px;
  cursor: pointer;
}

.date-aply-btn {
  width: 84px;
  height: 28px;
  color: #ffffff;
  background: #044b7f;
  border-radius: 5px;
  border: none;
  font-size: 12px;
}
.dateSize {
  margin-left: 40px;
  width: 160px;
}
.dt-cln-btn {
  width: 84px;
  height: 28px;
  color: #044b7f;
  background: #bad2e4;
  border-radius: 5px;
  border: none;
  font-size: 12px;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #797979;
}
.date-lable {
  font-weight: 600;
  font-size: 10px;
  color: #272727;
}
.date-picker-style {
  background: #ffffff;
  width: 150px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
}

.pr-4 {
  padding-right: 4px;
}

.ml-11 {
  margin-left: 11px;
}
.bg-measurement {
  background: #f8f8f8 !important;
}
.m-btn {
  margin: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/filterOptions.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,eAAe;AACjB","sourcesContent":[".date-icon-filter {\n  position: relative;\n  right: 25px;\n  top: 2px;\n  cursor: pointer;\n}\n\n.date-aply-btn {\n  width: 84px;\n  height: 28px;\n  color: #ffffff;\n  background: #044b7f;\n  border-radius: 5px;\n  border: none;\n  font-size: 12px;\n}\n.dateSize {\n  margin-left: 40px;\n  width: 160px;\n}\n.dt-cln-btn {\n  width: 84px;\n  height: 28px;\n  color: #044b7f;\n  background: #bad2e4;\n  border-radius: 5px;\n  border: none;\n  font-size: 12px;\n}\n\n.center-div {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #797979;\n}\n.date-lable {\n  font-weight: 600;\n  font-size: 10px;\n  color: #272727;\n}\n.date-picker-style {\n  background: #ffffff;\n  width: 150px;\n  border: 1px solid #d3d3d3;\n  border-radius: 5px;\n  margin-top: 5px;\n  padding: 5px;\n}\n\n.pr-4 {\n  padding-right: 4px;\n}\n\n.ml-11 {\n  margin-left: 11px;\n}\n.bg-measurement {\n  background: #f8f8f8 !important;\n}\n.m-btn {\n  margin: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
