import { Popper } from "@mui/base";
import {
  Box,
  CircularProgress,
  Fade,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {ClickAwayListener} from "@mui/base/ClickAwayListener";

import React from "react";
import {
  NoResultsFoundIcon,
  NoDoctorsFound,
  NoSpecialitiesFound,
} from "components/UI/Icons";
import noProfilePic from "assets/images/empty_pic.svg";
import SearchIcon from "assets/images/SearchIcon.svg";
import { useNavigate } from "react-router-dom";

const GlobalSearch = ({
  handleClickAway,
  handleGlobalSearchFocus,
  handleInputChange,
  globalSearchAnchorEl,
  isSearching,
  globalSearchList,
  globalSearchId,
  openGlobalSearchList,
  globalSearchText,
  setShowNoHcpPopup,
  connectedHCPLength,
}) => {
  const navigate = useNavigate();

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box id="global-search" sx={{ position: "relative" }}>
        <TextField
          placeholder="Search for Specialties, Doctors"
          onChange={handleInputChange}
          onFocus={handleGlobalSearchFocus}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box component={"img"} src={SearchIcon} alt="" />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          sx={{
            width: "19.6875rem",
            "& input": { p: ".5rem", fontSize: "0.875rem" },
          }}
        />

        <Box
          component={Popper}
          sx={{ zIndex: 999 }}
          id={globalSearchId}
          open={openGlobalSearchList && globalSearchText !== ""}
          anchorEl={globalSearchAnchorEl}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box
                sx={{
                  borderRadius: "5px",
                  background: " #FFF",
                  boxShadow: " 4px 8px 16px 0px rgba(0, 0, 0, 0.16)",
                  marginTop: "19px",
                  bgcolor: "background.paper",
                  width: "315px",
                  height: "411px",
                  overflowY: "auto",
                }}
              >
                {isSearching ? (
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={"2rem"} />
                  </Box>
                ) : globalSearchList.practitioners.length === 0 &&
                  globalSearchList.specialities.length === 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "11px",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={NoResultsFoundIcon}
                      sx={{
                        "& path": {
                          stroke: (theme) => theme.palette.primary.main,
                        },
                      }}
                    />
                    <Box
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      No results found!
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box>
                      <Box>
                        <Box
                          sx={{
                            background: "rgba(236, 248, 254, 1)",
                            color: "black",

                            fontWeight: 600,
                            fontSize: "14px",

                            padding: "14px 7px",
                          }}
                        >
                          Specialities
                        </Box>

                        {globalSearchList.specialities.length === 0 ? (
                          <Box
                            sx={{
                              padding: "9px 9px",
                              borderRadius: "5px",
                              background: "#F9F9F9",
                              margin: "0 11px 11px",
                              display: "grid",
                              gridTemplateColumns: "50px 1fr",
                              gap: "17px",
                            }}
                          >
                            <Box
                              component={NoSpecialitiesFound}
                              sx={{
                                objectFit: "cover",
                                width: "50px",
                                height: "50px",
                                p: "10px",
                                "& path": {
                                  fill: (theme) => theme.palette.primary.main,
                                },
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "selfStart",
                              }}
                            >
                              <Box
                                sx={{
                                  color: "#272727",
                                  fontSize: "12px",
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                No Specialities found!
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          globalSearchList.specialities.map((item) => {
                            return (
                              <Box
                                sx={{
                                  padding: "9px 9px",
                                  borderRadius: "5px",
                                  background: "#F9F9F9",
                                  margin: "0 11px 11px",
                                  display: "flex",
                                  alignItems: "center",
                                  "& > * + *": { ml: "1rem" },
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (connectedHCPLength <= 0) {
                                    setShowNoHcpPopup(
                                      `/patient/dashboard/doctors_list/${item?.id}`
                                    );
                                  } else {
                                    navigate(
                                      `/patient/dashboard/doctors_list/${item?.id}`
                                    );
                                  }
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={item?.attachment_url}
                                  sx={{
                                    borderRadius: "99rem",
                                    width: "50px",
                                    height: "50px",
                                  }}
                                />

                                <Box
                                  title={item?.display}
                                  sx={{ width: "30ch", overflow: "hidden" }}
                                >
                                  <Typography
                                    fontSize={12}
                                    sx={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item?.display}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          background: "rgba(236, 248, 254, 1)",
                          color: "black",

                          fontWeight: 600,
                          fontSize: "14px",

                          padding: "14px 7px",
                        }}
                      >
                        Doctors
                      </Box>
                      {globalSearchList.practitioners.length === 0 ? (
                        <Box
                          sx={{
                            padding: "9px 9px",
                            borderRadius: "5px",
                            background: "#F9F9F9",
                            margin: "0 11px 11px",
                            display: "grid",
                            gridTemplateColumns: "50px 1fr",
                            gap: "17px",
                          }}
                        >
                          <Box
                            component={NoDoctorsFound}
                            sx={{
                              objectFit: "cover",
                              width: "50px",
                              height: "50px",
                              p: "10px",
                              "& path": {
                                fill: (theme) => theme.palette.primary.main,
                              },
                            }}
                          />

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "selfStart",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                color: "#272727",
                                fontSize: "12px",
                                fontFamily: "Open Sans",
                                fontWeight: 600,
                              }}
                            >
                              No Doctors found!
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        globalSearchList.practitioners.map((item) => {
                          return (
                            <Box
                              sx={{
                                padding: "9px 9px",
                                borderRadius: "5px",
                                background: "#F9F9F9",
                                margin: "0 11px 11px",
                                display: "grid",
                                gridTemplateColumns: "50px 1fr",
                                gap: "17px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowNoHcpPopup(
                                  `/patient/dashboard/doctors_list/about_doctor/${item?.id}`
                                );
                              }}
                            >
                              <Box
                                component={"img"}
                                src={
                                  item?.avatar_url === null
                                    ? noProfilePic
                                    : item?.avatar_url
                                }
                                sx={{
                                  objectFit: "cover",
                                  borderRadius: "99rem",
                                  width: "50px",
                                  height: "50px",
                                }}
                              />

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#272727",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item?.full_name}
                                </Typography>
                                <Box title={item?.specializations?.join(", ")}>
                                  <Typography
                                    fontSize={10}
                                    sx={{
                                      color: "#888",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "30ch",
                                    }}
                                  >
                                    {item?.specializations?.join(", ")}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Fade>
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default GlobalSearch;
