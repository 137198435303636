// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proceed-btn {
  width: 226px;
}

.upload-items {
  gap: 47px;
  padding: 0 18px;
}

.onClose {
  padding: 7px;
}
.norecord-reports {
  height: calc(100vh - 217px);
}

.search-icon {
  position: absolute;
  left: 21px;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}

.search-input {
  padding-left: 41px;
  width: 315px !important;
}
.custom-menu {
  --bs-dropdown-min-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/UploadPopUp.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,WAAW;EACX,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".proceed-btn {\n  width: 226px;\n}\n\n.upload-items {\n  gap: 47px;\n  padding: 0 18px;\n}\n\n.onClose {\n  padding: 7px;\n}\n.norecord-reports {\n  height: calc(100vh - 217px);\n}\n\n.search-icon {\n  position: absolute;\n  left: 21px;\n  top: 50%;\n  bottom: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.search-input {\n  padding-left: 41px;\n  width: 315px !important;\n}\n.custom-menu {\n  --bs-dropdown-min-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
