// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.point {
  cursor: pointer;
}
.healthpagelogo {
  display: none !important;
}
.healthpagebutton {
  display: flex;
  justify-content: flex-end !important;
}
.headerposition {
  position: relative !important;
}
.header-user-image {
  width: 30px;
  height: 30px !important;
  border: 2px lightgrey solid;
  border-radius: 50%;
}

.cancelBtn {
  background: #d6d4d4;
  border: none !important;
  width: 105px;
  height: 38px;
  color: black !important;
  font-weight: 700;
  font-family: "open sans", sans-serif !important;
  box-shadow: none;
}
.cancelBtn:hover {
  background: #d6d4d4;
}

.mb-10 {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/Header.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,oCAAoC;AACtC;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,WAAW;EACX,uBAAuB;EACvB,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;EAChB,+CAA+C;EAC/C,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".point {\n  cursor: pointer;\n}\n.healthpagelogo {\n  display: none !important;\n}\n.healthpagebutton {\n  display: flex;\n  justify-content: flex-end !important;\n}\n.headerposition {\n  position: relative !important;\n}\n.header-user-image {\n  width: 30px;\n  height: 30px !important;\n  border: 2px lightgrey solid;\n  border-radius: 50%;\n}\n\n.cancelBtn {\n  background: #d6d4d4;\n  border: none !important;\n  width: 105px;\n  height: 38px;\n  color: black !important;\n  font-weight: 700;\n  font-family: \"open sans\", sans-serif !important;\n  box-shadow: none;\n}\n.cancelBtn:hover {\n  background: #d6d4d4;\n}\n\n.mb-10 {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
