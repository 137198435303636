// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.del-btns {
  margin: 12px 0px;
  padding: 0px 12px;
}
.del-no-btn {
  width: 103px;
  height: 42px;
  background: #ffffff;
  border: 2px solid #044b7f;
  border-radius: 5px;
  color: #044b7f;
}

.del-yes-btn {
  width: 117px;
  height: 43px;
  background: #044b7f;
  border-radius: 5px;
  color: #ffffff;
}

.delete-msg {
  padding: 18px;
}
.del-txt {
  padding: 20px 0px;
  margin: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/DeleteAlert.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".del-btns {\n  margin: 12px 0px;\n  padding: 0px 12px;\n}\n.del-no-btn {\n  width: 103px;\n  height: 42px;\n  background: #ffffff;\n  border: 2px solid #044b7f;\n  border-radius: 5px;\n  color: #044b7f;\n}\n\n.del-yes-btn {\n  width: 117px;\n  height: 43px;\n  background: #044b7f;\n  border-radius: 5px;\n  color: #ffffff;\n}\n\n.delete-msg {\n  padding: 18px;\n}\n.del-txt {\n  padding: 20px 0px;\n  margin: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
