// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-clr {
  background: #f5f5f5;
  border: none !important;
  color: black !important;
}

.btn-clr:hover {
  background: #d6d4d4;
}

.ProfileBtn {
  width: 105px;
  box-shadow: none !important;

  border: none !important;
  /* color: black !important; */
}

.MyProfile {
  font-size: 22px;
  font-weight: bolder;
}

.ml-15 {
  margin-left: 16px;
}
.mb-6 {
  margin-bottom: 6px;
}
.camelCase {
  text-transform: capitalize;
}
.clock-time {
  width: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/PatientProfile.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,2BAA2B;;EAE3B,uBAAuB;EACvB,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,WAAW;AACb","sourcesContent":[".btn-clr {\n  background: #f5f5f5;\n  border: none !important;\n  color: black !important;\n}\n\n.btn-clr:hover {\n  background: #d6d4d4;\n}\n\n.ProfileBtn {\n  width: 105px;\n  box-shadow: none !important;\n\n  border: none !important;\n  /* color: black !important; */\n}\n\n.MyProfile {\n  font-size: 22px;\n  font-weight: bolder;\n}\n\n.ml-15 {\n  margin-left: 16px;\n}\n.mb-6 {\n  margin-bottom: 6px;\n}\n.camelCase {\n  text-transform: capitalize;\n}\n.clock-time {\n  width: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
