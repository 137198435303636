// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-app-blue {
  color: var(--app-blue-clr) !important;
}

.text-app-black {
  color: var(--app-black-clr) !important;
}

.bg-app-blue {
  background-color: var(--app-blue-clr) !important;
}

.text-black {
  color: #000;
}

.text-lg {
  font-size: large;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 50%);
}
.err-msg {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/Utilities.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;AACvC;AACA;EACE,UAAU;AACZ","sourcesContent":[".text-app-blue {\n  color: var(--app-blue-clr) !important;\n}\n\n.text-app-black {\n  color: var(--app-black-clr) !important;\n}\n\n.bg-app-blue {\n  background-color: var(--app-blue-clr) !important;\n}\n\n.text-black {\n  color: #000;\n}\n\n.text-lg {\n  font-size: large;\n}\n\n.grid-cols-2 {\n  grid-template-columns: repeat(2, 50%);\n}\n.err-msg {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
